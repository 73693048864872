
<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <!-- Add New -->
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('VirtualMuseum.configuration.tour_operator') + ' ' + $t('globalTrans.list')}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-3 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <!-- /Add New -->
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">

                  <!-- Table -->
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>

                    <template v-slot:cell(mobile_no)="data">
                      {{ ($i18n.locale == 'bn' ? '০': '0') + $n(data.item.mobile_no, { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(status)="data">
                      <span class="badge badge-danger" v-if="data.item.status == 2">{{$t('globalTrans.inactive')}}</span>
                      <span class="badge badge-success" v-else>{{$t('globalTrans.active')}}</span>
                    </template>

                    <template v-slot:cell(action)="data">
                      <a href="javascript:" :title="$t('globalTrans.edit')" class="btn_table_action table_action_edit" v-b-modal.modal-3 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                      <a href="javascript:" :title="$t('globalTrans.active')" class="btn_table_action table_action_status" v-if="data.item.status == 1" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                      <a href="javascript:" :title="$t('globalTrans.inactive')" class="btn_table_action table_action_toggle" v-else @click="remove(data.item)"><i class="fas fa-toggle-off"></i></a>
                    </template>
                  </b-table>

                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal id="modal-3" size="lg" :title="formTitle"  :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal>
  </b-container>
</template>
<script>
import Form from './Form'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { tourOperatorListApi, tourOperatorToggleApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
     Form
  },
  data () {
    return {
      search: {
        information_type: ''
      },
      editItemId: 0,
      rows: []
    }
  },
  computed: {
    formTitle () {
        if (this.editItemId === 0) {
            return this.$t('VirtualMuseum.configuration.tour_operator') + ' ' + this.$t('globalTrans.entry')
        } else {
            return this.$t('VirtualMuseum.configuration.tour_operator') + ' ' + this.$t('globalTrans.modify')
        }
    },
    columns () {
      // Table Column Labels
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('globalTrans.organization'), class: 'text-center' },
          { label: this.$t('VirtualMuseum.configuration.name'), class: 'text-center' },
          { label: this.$t('VirtualMuseum.configuration.designation'), class: 'text-center' },
          { label: this.$t('VirtualMuseum.configuration.mobile_no'), class: 'text-center' },
          { label: this.$t('VirtualMuseum.configuration.email'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center fixed-width' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
          { key: 'name' },
          { key: 'designation' },
          { key: 'mobile_no' },
          { key: 'email' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'name' },
          { key: 'designation' },
          { key: 'mobile_no' },
          { key: 'email' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  created () {
    this.loadData()
  },
  watch: {
  },
  methods: {
    edit (item) {
      this.editItemId = item.id
    },
    searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(virtualMuseumServiceBaseUrl, tourOperatorToggleApi, item)
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(virtualMuseumServiceBaseUrl, tourOperatorListApi, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getRelationalData(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
            let orgList = {}
            const listData = data.map(item => {
                orgList = this.$store.state.commonObj.organizationProfileList.find(org => org.value === item.org_id)
                const data = {
                    org_name: orgList !== undefined ? orgList.text_en : '',
                    org_name_bn: orgList !== undefined ? orgList.text_bn : ''
                }

                return Object.assign({}, item, data)
            })
            return listData
    }
  }
}
</script>
<style lang="scss">
  .fixed-width {
    width: 180px !important;
  }
</style>
